import React, { useState } from "react";
import { Alert, PortalSetting, Location } from "../components";
import {
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  Input,
  Collapse,
  Button,
} from "reactstrap";
import {
  ISettings,
  emptySettings,
  resetKeywordValue,
} from "../models/ISettings";
import {
  ECategory,
  ECategoryLabel,
  CategoryDefaultLabel,
  ESortLabel,
  ESort,
  EPortalIcon,
  EPortalLabel,
} from "../models/enums";

interface ISettingsProps {
  settings: ISettings;
  setSettings: (setting: ISettings) => void;
  isAdvancedSettingsShow: boolean;
  search(force: boolean, settings?: ISettings): void;
}

export const Settings: React.FC<ISettingsProps> = ({
  settings,
  setSettings,
  isAdvancedSettingsShow,
  search,
}) => {
  const [isSettingsCleared, setIsSettingsCleared] = useState<boolean>(false);

  const filterHeader = (name: string) => (
    <p className="m-2" style={{ color: "white" }}>
      {name}
    </p>
  );

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { id, value } = e.target;

    if (!Boolean(value)) setSettings({ ...settings, [id]: undefined });

    value = value.replace(/\D/g, "");

    const regex = /^[0-9\b]+$/;

    if (regex.test(value)) {
      const targetNumber = parseInt(value);

      if (targetNumber < 100000000)
        setSettings({ ...settings, [id]: targetNumber });
    }
  };

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") search(true, settings);
  };

  const clearSettings = () => {
    setSettings({ ...emptySettings, keyword: resetKeywordValue });
    window.history.pushState(null, "", "/");
    setIsSettingsCleared(true);
  };

  return (
    <>
      <Alert
        color="success"
        visible={isSettingsCleared}
        setIsAlertVisible={setIsSettingsCleared}
      >
        Filtr byl úspěšně zrušen.
      </Alert>
      <Row>
        <Col xl={2} lg={3} mb={6} sm={3} xs={6} className="text-truncate">
          {filterHeader("Částka od")}
          <InputGroup>
            <Input
              id="amountFrom"
              value={
                settings.amountFrom
                  ? settings.amountFrom.toLocaleString("cs-CZ")
                  : ""
              }
              onChange={handleAmountChange}
              onKeyPress={handleEnterPress}
              type="text"
            />
            <InputGroupAddon addonType="append">Kč</InputGroupAddon>
          </InputGroup>
        </Col>
        <Col xl={2} lg={3} mb={2} sm={3} xs={6} className="text-truncate">
          {filterHeader("do")}
          <InputGroup>
            <Input
              id="amountTo"
              value={
                settings.amountTo
                  ? settings.amountTo.toLocaleString("cs-CZ")
                  : ""
              }
              onChange={handleAmountChange}
              onKeyPress={handleEnterPress}
              type="text"
            />
            <InputGroupAddon addonType="append">Kč</InputGroupAddon>
          </InputGroup>
        </Col>
        <Col xl={2} lg={3} mb={3} sm={4} xs={6}>
          {filterHeader("Řazení")}
          <Input
            type="select"
            name="select"
            value={settings.sort}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSettings({ ...settings, sort: parseInt(e.target.value) })
            }
          >
            <option value={ESort.Latest}>{ESortLabel.Latest}</option>
            <option value={ESort.Cheapest}>{ESortLabel.Cheapest}</option>
            <option value={ESort.MostExpensive}>
              {ESortLabel.MostExpensive}
            </option>
          </Input>
        </Col>
        <Col xl={2} lg={3} mb={3} sm={4} xs={6}>
          {filterHeader("Kategorie")}
          <Input
            type="select"
            name="select"
            value={settings.category}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSettings({ ...settings, category: parseInt(e.target.value) })
            }
          >
            <option value={undefined}>{CategoryDefaultLabel}</option>
            <option value={ECategory.AutoMoto}>
              {ECategoryLabel.AutoMoto}
            </option>
            <option value={ECategory.Zvirata}>{ECategoryLabel.Zvirata}</option>
            <option value={ECategory.DetskyBazar}>
              {ECategoryLabel.DetskyBazar}
            </option>
            <option value={ECategory.Reality}>{ECategoryLabel.Reality}</option>
            <option value={ECategory.Elektronika}>
              {ECategoryLabel.Elektronika}
            </option>
            <option value={ECategory.DumZahradaStroje}>
              {ECategoryLabel.DumZahradaStroje}
            </option>
            <option value={ECategory.Nabytek}>{ECategoryLabel.Nabytek}</option>
            <option value={ECategory.Sport}>{ECategoryLabel.Sport}</option>
            <option value={ECategory.HudbaKnihyZabava}>
              {ECategoryLabel.HudbaKnihyZabava}
            </option>
            <option value={ECategory.ObleceniObuvDoplnky}>
              {ECategoryLabel.ObleceniObuvDoplnky}
            </option>
            <option value={ECategory.Sluzby}>{ECategoryLabel.Sluzby}</option>
            <option value={ECategory.ZdraviKrasa}>
              {ECategoryLabel.ZdraviKrasa}
            </option>
            <option value={ECategory.OstatniStarozitnostiUmeni}>
              {ECategoryLabel.OstatniStarozitnostiUmeni}
            </option>
          </Input>
        </Col>
        <Col xl={4} lg={6} mb={6} sm={6} xs={12}>
          {filterHeader("Lokalita")}
          <Location settings={settings} setSettings={setSettings} />
        </Col>
      </Row>
      <Collapse isOpen={isAdvancedSettingsShow}>
        {filterHeader("Portály")}
        <Row>
          <PortalSetting
            value={settings.aukro}
            onChange={() =>
              setSettings({ ...settings, aukro: !settings.aukro })
            }
            title={EPortalLabel.Aukro}
            iconName={EPortalIcon.Aukro}
          />
          <PortalSetting
            value={settings.bazos}
            onChange={() =>
              setSettings({ ...settings, bazos: !settings.bazos })
            }
            title={EPortalLabel.Bazos}
            iconName={EPortalIcon.Bazos}
          />
          <PortalSetting
            value={settings.marketplace}
            onChange={() =>
              setSettings({ ...settings, marketplace: !settings.marketplace })
            }
            title={EPortalLabel.Marketplace}
            iconName={EPortalIcon.Marketplace}
            margin={false}
          />
          <PortalSetting
            value={settings.sbazar}
            onChange={() =>
              setSettings({ ...settings, sbazar: !settings.sbazar })
            }
            title={EPortalLabel.Sbazar}
            iconName={EPortalIcon.Sbazar}
            margin={false}
          />
        </Row>
        <Row className="text-right">
          <Col className="mt-4">
            <Button color="danger" onClick={clearSettings}>
              Zrušit filtr
            </Button>
          </Col>
        </Row>
      </Collapse>
    </>
  );
};
