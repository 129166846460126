import React, { useState, useEffect } from "react";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  DropdownMenu,
  DropdownItem,
  InputGroupText,
  Spinner,
  Dropdown,
  Button,
} from "reactstrap";
import { ISettings, emptySettings } from "../models/ISettings";
import { ILocale } from "../models/ILocale";
import { api } from "../utils/api";
import { Alert } from "../components";

interface ILocationProps {
  settings: ISettings;
  setSettings: (setting: ISettings) => void;
}

export const Location: React.FC<ILocationProps> = ({
  settings,
  setSettings,
}) => {
  const [value, setValue] = useState<string>(settings.location.name);
  const [isDropdownShow, setIsDropdownShow] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    isSearchingUserLocation,
    setIsSearchingUserLocation,
  ] = useState<boolean>(false);
  const [localeData, setLocaleData] = useState<ILocale[] | undefined>(
    undefined
  );
  const [isFetchLodalityFailed, setIsFetchLodalityFailed] = useState<boolean>(
    false
  );

  const setLocation = (location: ILocale) => {
    setSettings({
      ...settings,
      location: {
        ...settings.location,
        name: location.name,
        postCode: location.postCode,
        latitude: location.latitude,
        longitude: location.longitude,
      },
    });
  };

  const radiusValue = (value: number): string => `+ ${value.toString()} km`;

  useEffect(() => {
    setValue(settings.location.name);
  }, [settings.location.name]);

  useEffect(() => {
    const fetchApi = async function fetchMyAPI() {
      try {
        setLocaleData(
          await api("POST", "Location/GetLocations", {
            top: 10,
            keyword: value,
          })
        );
      } catch (e) {
        console.log(e);
        setIsFetchLodalityFailed(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (value.length >= 3) {
      setIsLoading(true);
      fetchApi();
    } else {
      setLocaleData(undefined);
    }
  }, [value]);

  const computeActualPosition = async (position: Position) => {
    setIsSearchingUserLocation(true);
    try {
      const result: ILocale = await api("POST", "Location/GetLocationByGps", {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });

      if (!Boolean(result)) {
        setIsFetchLodalityFailed(true);
      } else {
        setLocation(result);
        setValue(result.name);
      }
    } catch (e) {
      console.log(e);
      setIsFetchLodalityFailed(true);
    } finally {
      setIsSearchingUserLocation(false);
    }
  };

  const dropdown = () => {
    if (localeData === undefined) return <DropdownItem>Pište...</DropdownItem>;

    if (localeData.length === 0)
      return <DropdownItem>Žádný záznam</DropdownItem>;

    return results();
  };

  const results = () =>
    localeData?.map((location, index) => {
      return (
        <DropdownItem
          id={location.postCode}
          key={index}
          onMouseDown={() => {
            setValue(location.name);
            setLocation(location);
          }}
        >
          {location.postCode.substring(0, 3) +
            " " +
            location.postCode.substring(3) +
            " - " +
            location.name}
        </DropdownItem>
      );
    });

  return (
    <>
      <Alert
        setIsAlertVisible={setIsFetchLodalityFailed}
        visible={isFetchLodalityFailed}
        color="danger"
      >
        Načtení aktuální polohy se nezdařilo, opakujte prosím akci později.
      </Alert>
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            {isLoading ? (
              <Spinner size="sm" color="primary" />
            ) : (
              <i className="ni ni-pin-3" />
            )}
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder="Vyberte..."
          type="text"
          onFocus={() => setIsDropdownShow(true)}
          onBlur={() => {
            setIsDropdownShow(false);

            if (!Boolean(value)) {
              setLocation(emptySettings.location);
              setValue(value);
            } else if (value !== settings.location.name) {
              setValue(settings.location.name);
            }
          }}
          value={value}
          onChange={(e: React.FormEvent<HTMLInputElement>) =>
            setValue(e.currentTarget.value)
          }
        />
        {Boolean(settings.location.name) ? (
          <>
            <InputGroupAddon
              addonType="append"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="vertical-line" />
            </InputGroupAddon>
            <Input
              type="select"
              className="form-control-location p-0"
              defaultValue={settings.location.radius}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSettings({
                  ...settings,
                  location: {
                    ...settings.location,
                    radius: parseInt(e.target.value),
                  },
                })
              }
            >
              <option value={5}>{radiusValue(5)}</option>
              <option value={10}>{radiusValue(10)}</option>
              <option value={20}>{radiusValue(20)}</option>
              <option value={50}>{radiusValue(50)}</option>
            </Input>
          </>
        ) : null}
        <InputGroupAddon addonType="append">
          <Button
            style={{
              borderTopRightRadius: "0.375rem",
              borderBottomRightRadius: "0.375rem",
            }}
            size="sm"
            color="primary"
            onClick={() =>
              navigator.geolocation.getCurrentPosition(
                (pos: Position) => computeActualPosition(pos),
                (err: PositionError) => console.error(err)
              )
            }
          >
            {isSearchingUserLocation ? (
              <Spinner size="sm" color="primary" className="mr-2" />
            ) : (
              <i className="ni ni-send mr-2" />
            )}
            Vaše poloha
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <InputGroup>
        <Dropdown className="w-100">
          <DropdownMenu className={"w-100 " + (isDropdownShow ? "show" : "")}>
            {dropdown()}
          </DropdownMenu>
        </Dropdown>
      </InputGroup>
    </>
  );
};
