export const getCurrencyFormat = (value: number) => {
  return new Intl.NumberFormat("cs-CZ").format(Number(value));
};

export const getPriceText = (price: number) => {
  if (price === 0) {
    return "Zdarma";
  }
  if (price === -1) {
    return "Dohodou";
  }
  if (price === -2) {
    return "V textu";
  }
  return getCurrencyFormat(price) + " Kč";
};
