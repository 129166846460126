export enum EPortal {
  Bazos = 1,
  Marketplace = 2,
  Sbazar = 3,
  Aukro = 4,
}

export enum EPortalLabel {
  Bazos = "Bazoš",
  Marketplace = "Marketplace",
  Sbazar = "Sbazar",
  Aukro = "Aukro",
}

export enum EPortalIcon {
  Bazos = "bazos",
  Marketplace = "facebook",
  Sbazar = "seznam",
  Aukro = "aukro",
}

export const EPortalLabelMapper: Record<EPortal, EPortalLabel> = {
  [EPortal.Bazos]: EPortalLabel.Bazos,
  [EPortal.Marketplace]: EPortalLabel.Marketplace,
  [EPortal.Sbazar]: EPortalLabel.Sbazar,
  [EPortal.Aukro]: EPortalLabel.Aukro,
};

export const EPortalIconMapper: Record<EPortal, EPortalIcon> = {
  [EPortal.Bazos]: EPortalIcon.Bazos,
  [EPortal.Marketplace]: EPortalIcon.Marketplace,
  [EPortal.Sbazar]: EPortalIcon.Sbazar,
  [EPortal.Aukro]: EPortalIcon.Aukro,
};
