export const cleanUrl = () => {
  const ourParams = ["keyword", "sort", "from", "to", "location"];

  const urlParams = new URLSearchParams(window.location.search);
  const oldQuery = urlParams.toString();

  for (var key of urlParams.keys()) {
    if (!ourParams.includes(key)) urlParams.delete(key);
  }

  const newQuery = urlParams.toString();

  if (newQuery === "" && window.location.href.includes("?")) {
    window.history.pushState({}, "", "/");
  }

  if (newQuery !== oldQuery) window.history.pushState({}, "", newQuery);
};

export const isLandingShowed = () => {
  const landingSeesinStoreName = "landing";
  const isSessinStoreItemExists = Boolean(
    sessionStorage.getItem(landingSeesinStoreName)
  );

  sessionStorage.setItem(landingSeesinStoreName, "bazzy");

  return isSessinStoreItemExists;
};
