import React from "react";
import ReactDOM from "react-dom";
import { App } from "./components/App";
import * as serviceWorker from "./serviceWorker";
import "./assets/css/index.css";
import "./assets/css/argon-dashboard-react.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import { initSettngs } from "./models/ISettings";
import { Landing } from "./components";
import { motion } from "framer-motion";
import { cleanUrl, isLandingShowed } from "./utils/common";

cleanUrl();

const showLandingPage = isLandingShowed();
const rootElement = document.getElementById("root");

if (!showLandingPage) ReactDOM.render(<Landing />, rootElement);

const { settings, searchOnLoad } = initSettngs();

const app = (
  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
    <App {...settings} searchOnLoad={searchOnLoad} />
  </motion.div>
);

setTimeout(
  () => {
    ReactDOM.render(app, rootElement);
  },
  showLandingPage ? 0 : 3000
);

serviceWorker.register();
