const ACCEPT_JSON = "application/json;charset=utf-8";
const CONTENT_TYPE_JSON = "application/json;charset=utf-8";

export const api = async (
  method: string,
  path: string,
  data?: any,
  abortController?: AbortController
) => callApiBase(method, "api/" + path, data, abortController);

async function callApiBase(
  method: string,
  endpoint: string,
  data?: any,
  abortController?: AbortController
) {
  const headers: any = {
    Accept: ACCEPT_JSON,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": CONTENT_TYPE_JSON
  };

  const res = await fetch(endpoint, {
    method,
    headers,
    body: JSON.stringify(data),
    signal: abortController ? abortController.signal : null
  });

  if (!res.ok) {
    throw await res.json();
  }

  return res.status !== 204 ? res.json() : null;
}
