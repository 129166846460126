import React from "react";
import { Navbar, NavbarBrand, Row, Col } from "reactstrap";
import { Logo } from "../components/Logo";

export const Header: React.FC = () => {
  return (
    <Navbar className="justify-content-center h-100 pt-0">
      <Row>
        <Col lg={8} xs={6}>
          <NavbarBrand href="./">
            <Logo />
          </NavbarBrand>
        </Col>
        <Col lg={4} xs={6} className="mt-3 d-flex align-items-center">
          <h4 style={{ color: "#fff" }}>...hledáme za vás!</h4>
        </Col>
      </Row>
    </Navbar>
  );
};
