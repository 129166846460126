import React, { useState, useImperativeHandle } from "react";
import * as reactstrap from "reactstrap";
import { api } from "../utils";
import { Alert } from "./Alert";

export interface IFeedbackModalRef {
  toggleModal: () => void;
}

interface IFeedbackState {
  email: string;
  text: string;
}

const emptyState: IFeedbackState = { email: "", text: "" };

export const FeedbackModal = React.forwardRef<IFeedbackModalRef>((_, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [form, setForm] = useState<IFeedbackState>(emptyState);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isSuccessAlertVisible, setIsSuccessAlertVisible] = useState<boolean>(
    false
  );
  const [isErrorAlertVisible, setIsErrorAlertVisible] = useState<boolean>(
    false
  );
  const [isMailValid, setIsMailValid] = useState<boolean>(true);
  const [isTextValid, setIsTextValid] = useState<boolean>(true);
  const isFormValid = isTextValid && isMailValid;
  const isFormEmpty = form === emptyState;

  const toggle = () => setIsOpen(!isOpen);

  useImperativeHandle(ref, () => ({
    toggleModal: () => {
      toggle();
    },
  }));

  const sendFeedback = async () => {
    if (!isFormValid) return;

    setIsSending(true);

    try {
      await api("POST", "feedback/Send", form);

      setForm(emptyState);
      setIsSuccessAlertVisible(true);
    } catch (e) {
      console.log(e);
      setIsErrorAlertVisible(true);
    } finally {
      setIsSending(false);
      toggle();
    }
  };

  return (
    <>
      <Alert
        color="success"
        visible={isSuccessAlertVisible}
        setIsAlertVisible={setIsSuccessAlertVisible}
      >
        Feedback byl úšpěně odeslán, děkujeme!
      </Alert>
      <Alert
        color="danger"
        visible={isErrorAlertVisible}
        setIsAlertVisible={setIsErrorAlertVisible}
      >
        Feedback se bohužel nepodařilo odeslat, zkuste to prosím později.
      </Alert>
      <reactstrap.Modal size="lg" isOpen={isOpen} toggle={toggle}>
        <reactstrap.ModalHeader toggle={toggle}>
          Feedback
        </reactstrap.ModalHeader>
        <reactstrap.ModalBody>
          <p>
            Napadájí Vás nějaká vylepšení nebo jste narazili na chybu? Napiště
            nám!
          </p>
          <reactstrap.Form>
            <reactstrap.Input
              disabled={isSending}
              required={true}
              style={{
                border: "1px solid " + (isMailValid ? "#adb5bd" : "#f5365c"),
              }}
              placeholder="E-mail"
              type="email"
              value={form.email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsMailValid(e.currentTarget.validity.valid);
                setForm({
                  ...form,
                  email: e.target.value,
                });
              }}
            />
            {isMailValid ? null : (
              <p style={{ color: "#f5365c" }}>Nesprávný formát e-mailu</p>
            )}
            <reactstrap.Input
              disabled={isSending}
              required={true}
              className="mt-2"
              style={{
                border: "1px solid " + (isTextValid ? "#adb5bd" : "#f5365c"),
              }}
              placeholder="Text..."
              rows="5"
              type="textarea"
              value={form.text}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setIsTextValid(e.currentTarget.validity.valid);
                setForm({
                  ...form,
                  text: e.target.value,
                });
              }}
            />
            {isTextValid ? null : (
              <p style={{ color: "#f5365c" }}>Text musí být vyplněn</p>
            )}
          </reactstrap.Form>
        </reactstrap.ModalBody>
        <reactstrap.ModalFooter>
          <reactstrap.Button
            color="secondary"
            onClick={toggle}
            disabled={isSending}
          >
            Zrušit
          </reactstrap.Button>{" "}
          <reactstrap.Button
            style={{ color: "#fff", backgroundColor: "#5e72e4" }}
            color="primary"
            type="button"
            disabled={isFormEmpty || !isFormValid || isSending}
            onClick={sendFeedback}
          >
            Odeslat
          </reactstrap.Button>
        </reactstrap.ModalFooter>
      </reactstrap.Modal>
    </>
  );
});
