import React, { useEffect } from "react";
import { Alert as ReactstrapAlert } from "reactstrap";

interface IAlertProps {
  color: string;
  visible: boolean;
  setIsAlertVisible(show: boolean): void;
}

export const Alert: React.FC<IAlertProps> = ({
  color,
  visible,
  children,
  setIsAlertVisible,
}) => {
  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setIsAlertVisible(false);
      }, 5000);
    }
  }, [visible, setIsAlertVisible]);

  return (
    <ReactstrapAlert
      color={color}
      isOpen={visible}
      toggle={() => setIsAlertVisible(false)}
      className="custom-alert"
    >
      <span className="alert-inner--text">{children}</span>
    </ReactstrapAlert>
  );
};
