import React, { CSSProperties } from "react";
import { DropdownItem, Row, Col } from "reactstrap";
import {
  CategoryDefaultLabel,
  CategoryMap,
  EPortalIcon,
  ESortMapper,
} from "../models/enums";
import { ISettings } from "../models/ISettings";
import { PortalIcon } from "./PortalIcon";

interface ISearchHistoryItemProps {
  index: number;
  isActive: boolean;
  setting: ISettings;
  search(historyIndex?: number): void;
}

export const SearchHistoryItem: React.FC<ISearchHistoryItemProps> = ({
  index,
  isActive,
  setting,
  search,
}) => {
  const keyword = setting.keyword;
  const sort = ESortMapper[setting.sort];
  const categoryName = Boolean(setting.category)
    ? CategoryMap[setting.category ?? 1]
    : CategoryDefaultLabel;
  const amountFrom =
    setting.amountFrom !== undefined
      ? setting.amountFrom.toLocaleString("cs-CZ")
      : 0;
  const amountTo =
    setting.amountTo !== undefined
      ? setting.amountTo.toLocaleString("cs-CZ")
      : "Neurčito";
  const location = Boolean(setting.location.name)
    ? setting.location.name + " +" + setting.location.radius + " km"
    : "Celá ČR";

  const isAukroDisabled = !setting.aukro;
  const isBazosDisabled = !setting.bazos;
  const isMarketplaceDisabled = !setting.marketplace;
  const isSbazarDisabled = !setting.sbazar;

  const portalStyle: CSSProperties = {
    marginRight: ".2rem",
    border: "1px solid rgba(0, 0, 0, 0.05)",
  };

  return (
    <DropdownItem
      key={index}
      style={{ borderBottom: "1px solid #8898aa" }}
      active={isActive}
      onMouseDown={() => search(index)}
    >
      <Row className="h-100">
        <Col className="text-truncate align-self-center" xs={6} lg={3}>
          <strong>{keyword}</strong>
        </Col>
        <Col className="align-self-center" xs={6} lg={2}>
          {amountFrom + " - " + amountTo}
        </Col>
        <Col className="align-self-center" xs={6} lg={2}>
          {location}
        </Col>
        <Col className="align-self-center" xs={6} lg={2}>
          {categoryName}
        </Col>
        <Col
          className="align-self-center col-auto mr-auto"
          xs={3}
          lg={3}
          xl={2}
        >
          {sort}
        </Col>
        <Col className="col-auto">
          <Row style={{ marginTop: ".5rem" }}>
            <Col className="col-auto d-flex">
              <PortalIcon
                disabled={isAukroDisabled}
                iconName={EPortalIcon.Aukro}
                style={portalStyle}
              ></PortalIcon>
              <PortalIcon
                disabled={isBazosDisabled}
                iconName={EPortalIcon.Bazos}
                style={portalStyle}
              ></PortalIcon>
              <PortalIcon
                disabled={isMarketplaceDisabled}
                iconName={EPortalIcon.Marketplace}
                style={portalStyle}
              ></PortalIcon>
              <PortalIcon
                disabled={isSbazarDisabled}
                iconName={EPortalIcon.Sbazar}
                style={portalStyle}
              ></PortalIcon>
            </Col>
          </Row>
        </Col>
      </Row>
    </DropdownItem>
  );
};
