import { ISettings } from "../models";

export const scrollToElement = (element: React.RefObject<HTMLDivElement>) => {
  window.scrollTo({ behavior: "smooth", top: element.current?.offsetTop });
};

export const scrollHandler = (
  contentDiv: React.RefObject<HTMLDivElement>,
  showBackToTop: boolean,
  setShowBackToTop: React.Dispatch<React.SetStateAction<boolean>>,
  isAllResultsShowed: boolean,
  canSearch: boolean,
  search: (force: boolean, settings?: ISettings) => void
) => {
  const resultDivBoundingClient = contentDiv.current?.getBoundingClientRect();

  if (resultDivBoundingClient && resultDivBoundingClient?.top <= 0) {
    !showBackToTop ? setShowBackToTop(true) : void 0;
  } else {
    showBackToTop ? setShowBackToTop(false) : void 0;
  }

  const body = document.body;
  const html = document.documentElement;
  const windowHeight =
    "innerHeight" in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );
  const windowBottom = windowHeight + window.pageYOffset + 1;

  if (windowBottom >= docHeight && !isAllResultsShowed && canSearch) {
    search(false);
  }
};
