import { AnimatePresence, motion } from "framer-motion";
import React, { createRef } from "react";
import { FeedbackModal, IFeedbackModalRef } from "./FeedbackModal";

interface IFeedbackProps {
  show: boolean;
}

export const Feedback: React.FC<IFeedbackProps> = ({ show }) => {
  const modalRef = createRef<IFeedbackModalRef>();

  return (
    <AnimatePresence>
      {show ? (
        <motion.button
          className="btn feedback-button"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          color="primary"
          onClick={() => modalRef.current?.toggleModal()}
        >
          <i className="ni ni-notification-70"></i>
          <span>Feedback</span>
        </motion.button>
      ) : null}
      <FeedbackModal ref={modalRef} />
    </AnimatePresence>
  );
};
