import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  Spinner,
  Dropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  ISettings,
  settingsHistoryLocalStorageKey,
  compareSettings,
  resetKeywordValue,
} from "../models/ISettings";
import { SearchHistoryItem } from "./SearchHistoryItem";
import { Alert } from "./Alert";

interface ISearchBarProps {
  settings: ISettings;
  setSettings(settings: ISettings): void;
  search(force: boolean, settings: ISettings): void;
  isSearching: boolean;
}

export const SearchBar: React.FC<ISearchBarProps> = ({
  settings,
  setSettings,
  search,
  isSearching,
}) => {
  const [keyword, setKeyword] = useState<string>(settings.keyword);
  const [settingsHistory, setSettingsHistory] = useState<ISettings[]>([]);
  const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<number>(-1);
  const [isHistoryShowed, setIsHistoryShowed] = useState<boolean>(false);
  const [isClearHistoryShowed, setIsClearHistoryShowed] = useState<boolean>(
    false
  );

  const maxHistoryLength = 5;
  const canSearch = Boolean(keyword);
  const filteredSettingsHistory =
    keyword.length === 0 || selectedHistoryIndex !== -1
      ? settingsHistory
      : settingsHistory.filter((f) =>
          f.keyword.toLowerCase().includes(keyword.toLowerCase())
        );

  const haveHistoryItems = filteredSettingsHistory.length > 0;

  useEffect(() => {
    if (settings.keyword === resetKeywordValue) setKeyword("");
  }, [settings.keyword]);

  useEffect(() => {
    const historySettingValue = localStorage.getItem(
      settingsHistoryLocalStorageKey
    );

    if (historySettingValue !== undefined && historySettingValue !== null) {
      const history: ISettings[] = JSON.parse(historySettingValue);
      setSettingsHistory(history);
    }
  }, []);

  useEffect(() => {
    if (selectedHistoryIndex !== -1 && haveHistoryItems) {
      setKeyword(settingsHistory[selectedHistoryIndex].keyword);
    }
  }, [selectedHistoryIndex, settingsHistory, haveHistoryItems]);

  useEffect(() => {
    localStorage.setItem(
      settingsHistoryLocalStorageKey,
      JSON.stringify(settingsHistory)
    );
  }, [settingsHistory]);

  const clearKeyword = () => {
    setKeyword("");
  };

  const manageHistory = (newSettings: ISettings) => {
    let newHistory: ISettings[] = [];

    const duplicateIndex = settingsHistory.findIndex((s) =>
      compareSettings(s, newSettings)
    );

    newHistory.push(
      duplicateIndex === -1 ? newSettings : settingsHistory[duplicateIndex]
    );

    settingsHistory.forEach((s, index) => {
      if (index !== duplicateIndex && newHistory.length < maxHistoryLength) {
        newHistory.push(s);
      }
    });

    setSettingsHistory(newHistory);
  };

  const executeSearch = (historyIndex: number = selectedHistoryIndex) => {
    if (!Boolean(keyword) && historyIndex === -1) return;

    const newSettings =
      historyIndex === -1
        ? { ...settings, keyword }
        : filteredSettingsHistory[historyIndex];

    setKeyword(newSettings.keyword);
    setSettings(newSettings);
    search(true, newSettings);
    manageHistory(newSettings);
    onSearchHistoryHide();
  };

  const onSearchHistoryHide = () => {
    setIsHistoryShowed(false);
    setSelectedHistoryIndex(-1);
  };

  return (
    <Row>
      <Col>
        <FormGroup className="mb-0">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-zoom-split-in" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              autoFocus={true}
              className="pl-1"
              disabled={isSearching}
              placeholder="Co sháníte?"
              value={keyword}
              type="text"
              onFocus={() => setIsHistoryShowed(true)}
              onClick={() => setIsHistoryShowed(true)}
              onBlur={() => onSearchHistoryHide()}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Escape") {
                  onSearchHistoryHide();
                }

                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }

                if (
                  e.key === "ArrowDown" &&
                  selectedHistoryIndex + 1 < filteredSettingsHistory.length
                ) {
                  setSelectedHistoryIndex(selectedHistoryIndex + 1);
                }

                if (e.key === "ArrowUp" && selectedHistoryIndex > 0) {
                  setSelectedHistoryIndex(selectedHistoryIndex - 1);
                }
              }}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter" && canSearch) executeSearch();
              }}
              onChange={(e: React.FormEvent<HTMLInputElement>) => {
                setSelectedHistoryIndex(-1);
                setKeyword(e.currentTarget.value);
              }}
            />
            <InputGroupAddon addonType="append">
              {Boolean(keyword) ? (
                <InputGroupText onClick={clearKeyword}>
                  <i className="ni ni-fat-remove" />
                </InputGroupText>
              ) : null}
              <Button
                disabled={isSearching}
                color="primary"
                onClick={() => executeSearch()}
              >
                {isSearching ? <Spinner size="sm" color="primary" /> : "Hledat"}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <Alert
          color="success"
          visible={isClearHistoryShowed}
          setIsAlertVisible={setIsClearHistoryShowed}
        >
          Historie vyhledávání byla úspěšně vymazána.
        </Alert>
        <InputGroup>
          {haveHistoryItems &&
          (Boolean(keyword) || selectedHistoryIndex !== -1) ? (
            <Dropdown className="w-100">
              <DropdownMenu className={"w-100 " + (isHistoryShowed && "show")}>
                <DropdownItem style={{ color: "#adb5bd" }} header={true}>
                  <Row>
                    <Col>Historie hlednání</Col>
                    <Col
                      className="col-auto"
                      onMouseDown={() => {
                        setSettingsHistory([]);
                        setIsClearHistoryShowed(true);
                      }}
                    >
                      Vymazat historii
                    </Col>
                  </Row>
                </DropdownItem>
                {filteredSettingsHistory.map((setting, index) => (
                  <SearchHistoryItem
                    search={executeSearch}
                    key={index}
                    index={index}
                    setting={setting}
                    isActive={selectedHistoryIndex === index}
                  />
                ))}
              </DropdownMenu>
            </Dropdown>
          ) : null}
        </InputGroup>
        <hr className="mt-3 mb-1" />
      </Col>
    </Row>
  );
};
