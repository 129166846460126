import React from "react";
import { Row } from "reactstrap";
import { IResults } from "../models/IResults";
import { Complete, Loading, SearchResults, NoResults } from "./";

export interface IUserSearchProps {
  isLoading: boolean;
  results: IResults[];
  isShowedAll: boolean;
  isNoResult: boolean;
}

export const UserSearch: React.FC<IUserSearchProps> = ({
  isLoading,
  results,
  isShowedAll,
  isNoResult,
}) => {
  const pages = results.map((page, index) => (
    <SearchResults key={index} results={page.results} />
  ));

  return (
    <>
      <Row>{pages}</Row>
      {isShowedAll ? isNoResult ? <NoResults /> : <Complete /> : null}
      {isLoading ? <Loading /> : null}
    </>
  );
};
