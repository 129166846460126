export function getDateFormat(value?: Date) {
  const emptyValueText = "Nezjištěno";

  if (!value) {
    return emptyValueText;
  }

  const date = new Date(value);
  const year = date.getFullYear().toString();

  if (year === "1") {
    return emptyValueText;
  }

  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  if (month.length === 1) {
    month = `0${month}`;
  }

  if (day.length === 1) {
    day = `0${day}`;
  }

  return `${day}.${month}.${year}`;
}
