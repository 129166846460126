import React from "react";
import { Badge } from "reactstrap";

interface IPortalIconProps {
  iconName: string;
  totaHeight?: number;
  imageHeight?: number;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const PortalIcon: React.FC<IPortalIconProps> = ({
  iconName,
  totaHeight = 35,
  imageHeight = 20,
  disabled = false,
  style,
}) => {
  const image = require(`../assets/images/portals/${iconName}.png`);

  return (
    <Badge
      className="d-flex align-items-center justify-content-center"
      style={{
        width: totaHeight,
        height: totaHeight,
        backgroundColor: "#fff",
        zIndex: 100,
        ...style,
      }}
    >
      <img
        height={imageHeight}
        alt={iconName}
        src={image}
        style={disabled ? { filter: "grayscale(100%)" } : {}}
      />
    </Badge>
  );
};
