import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/landing.css";
import { motion } from "framer-motion";

export const Landing: React.FC = () => {
  return (
    <div className="landing-page">
      <Container fluid={true} style={{ position: "absolute", bottom: "50%" }}>
        <Row className="justify-content-center">
          <Col lg={4}>
            <svg viewBox="0 0 425 138" fill="none">
              <motion.g
                initial={{ opacity: 0 }}
                transition={{ duration: 2 }}
                animate={{ opacity: 1 }}
              >
                <g>
                  <g>
                    <path
                      d="M110.921 69.1606C110.921 92.7486 91.799 111.871 68.2109 111.871C44.6229 111.871 25.501 92.7486 25.501 69.1606C25.501 45.5726 44.6229 26.4507 68.2109 26.4507C91.799 26.4507 110.921 45.5726 110.921 69.1606Z"
                      stroke="white"
                      strokeWidth="7"
                    ></path>
                    <path
                      d="M94.0345 69.1608C94.0345 81.0403 90.8979 91.6334 86.0114 99.1504C81.1034 106.701 74.7324 110.77 68.211 110.77C61.6896 110.77 55.3186 106.701 50.4106 99.1504C45.5241 91.6334 42.3875 81.0403 42.3875 69.1608C42.3875 57.2812 45.5241 46.6881 50.4106 39.1711C55.3186 31.6209 61.6896 27.5513 68.211 27.5513C74.7324 27.5513 81.1034 31.6209 86.0114 39.1711C90.8979 46.6881 94.0345 57.2812 94.0345 69.1608Z"
                      stroke="white"
                      strokeWidth="7"
                    ></path>
                    <line
                      x1="68.307"
                      y1="24.0513"
                      x2="68.307"
                      y2="114.27"
                      stroke="white"
                      strokeWidth="7"
                    ></line>
                    <line
                      id="Line 2"
                      x1="27.3313"
                      y1="52.7036"
                      x2="109.815"
                      y2="52.4804"
                      stroke="white"
                      strokeWidth="7"
                    ></line>
                    <line
                      id="Line 3"
                      x1="25.9347"
                      y1="83.1656"
                      x2="110.444"
                      y2="82.6452"
                      stroke="white"
                      strokeWidth="7"
                    ></line>
                  </g>
                  <g id="cubes">
                    <g>
                      <rect
                        x="61.6753"
                        y="0.588379"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        fill="white"
                      ></rect>
                      <rect
                        x="97.8606"
                        y="6.63232"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(30 97.8606 6.63232)"
                        fill="white"
                      ></rect>
                      <rect
                        x="137.155"
                        y="62.2534"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(90 137.155 62.2534)"
                        fill="white"
                      ></rect>
                      <rect
                        x="0.010376"
                        y="62.2534"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        fill="white"
                      ></rect>
                      <rect
                        x="61.6753"
                        y="123.918"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        fill="white"
                      ></rect>
                      <rect
                        x="38.8875"
                        y="6.63232"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(60 38.8875 6.63232)"
                        fill="white"
                      ></rect>
                      <rect
                        x="120.44"
                        y="89.186"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(30 120.44 89.186)"
                        fill="white"
                      ></rect>
                      <rect
                        x="16.6963"
                        y="89.186"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(60 16.6963 89.186)"
                        fill="white"
                      ></rect>
                      <rect
                        x="34.2485"
                        y="112.819"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(30 34.2485 112.819)"
                        fill="white"
                      ></rect>
                      <rect
                        x="102.917"
                        y="112.819"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(60 102.917 112.819)"
                        fill="white"
                      ></rect>
                      <rect
                        x="11.6399"
                        y="30.2642"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(30 11.6399 30.2642)"
                        fill="white"
                      ></rect>
                      <rect
                        x="125.497"
                        y="30.2642"
                        width="13.8145"
                        height="13.8145"
                        rx="2"
                        transform="rotate(60 125.497 30.2642)"
                        fill="white"
                      ></rect>
                    </g>
                    <g>
                      <rect
                        x="63.7565"
                        y="2.66943"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="125.421"
                        y="64.3345"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="98.6223"
                        y="9.4751"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(30 98.6223 9.4751)"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="102.155"
                        y="115.662"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(60 102.155 115.662)"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="63.7565"
                        y="126"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="121.202"
                        y="92.0288"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(30 121.202 92.0288)"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="2.09155"
                        y="64.3345"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="35.0103"
                        y="115.559"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(30 35.0103 115.559)"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="12.4016"
                        y="33.1069"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(30 12.4016 33.1069)"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="15.9346"
                        y="92.0288"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(60 15.9346 92.0288)"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="38.1256"
                        y="9.4751"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(60 38.1256 9.4751)"
                        fill="#5E72E4"
                      ></rect>
                      <rect
                        x="124.735"
                        y="33.1069"
                        width="9.65222"
                        height="9.65222"
                        rx="1"
                        transform="rotate(60 124.735 33.1069)"
                        fill="#5E72E4"
                      ></rect>
                    </g>
                  </g>
                </g>
                <g id="landing-text">
                  <path
                    d="M158.847 37.6128H180.175C189.894 37.6128 196.941 39.0034 201.316 41.7847C205.722 44.5347 207.925 48.9253 207.925 54.9565C207.925 59.0503 206.956 62.4097 205.019 65.0347C203.112 67.6597 200.566 69.2378 197.378 69.769V70.2378C201.722 71.2065 204.847 73.019 206.753 75.6753C208.691 78.3315 209.659 81.8628 209.659 86.269C209.659 92.519 207.394 97.394 202.862 100.894C198.362 104.394 192.237 106.144 184.487 106.144H158.847V37.6128ZM173.378 64.7534H181.816C185.753 64.7534 188.597 64.144 190.347 62.9253C192.128 61.7065 193.019 59.6909 193.019 56.8784C193.019 54.2534 192.05 52.3784 190.112 51.2534C188.206 50.0972 185.175 49.519 181.019 49.519H173.378V64.7534ZM173.378 76.2847V94.144H182.847C186.847 94.144 189.8 93.3784 191.706 91.8472C193.612 90.3159 194.566 87.9722 194.566 84.8159C194.566 79.1284 190.503 76.2847 182.378 76.2847H173.378Z"
                    stroke="white"
                    strokeWidth="5"
                  />
                  <path
                    d="M255.503 106.144L252.737 99.019H252.362C249.956 102.05 247.472 104.16 244.909 105.347C242.378 106.503 239.066 107.082 234.972 107.082C229.941 107.082 225.972 105.644 223.066 102.769C220.191 99.894 218.753 95.8003 218.753 90.4878C218.753 84.9253 220.691 80.8315 224.566 78.2065C228.472 75.5503 234.347 74.0815 242.191 73.8003L251.284 73.519V71.2222C251.284 65.9097 248.566 63.2534 243.128 63.2534C238.941 63.2534 234.019 64.519 228.362 67.0503L223.628 57.394C229.659 54.2378 236.347 52.6597 243.691 52.6597C250.722 52.6597 256.112 54.1909 259.862 57.2534C263.612 60.3159 265.487 64.9722 265.487 71.2222V106.144H255.503ZM251.284 81.8628L245.753 82.0503C241.597 82.1753 238.503 82.9253 236.472 84.3003C234.441 85.6753 233.425 87.769 233.425 90.5815C233.425 94.6128 235.737 96.6284 240.362 96.6284C243.675 96.6284 246.316 95.6753 248.284 93.769C250.284 91.8628 251.284 89.3315 251.284 86.1753V81.8628Z"
                    stroke="white"
                    strokeWidth="5"
                  />
                  <path
                    d="M316.722 106.144H275.331V97.7065L299.612 64.6597H276.784V53.7378H315.878V63.019L292.253 95.2222H316.722V106.144Z"
                    stroke="white"
                    strokeWidth="5"
                  />
                  <path
                    d="M363.597 106.144H322.206V97.7065L346.487 64.6597H323.659V53.7378H362.753V63.019L339.128 95.2222H363.597V106.144Z"
                    stroke="white"
                    strokeWidth="5"
                  />
                  <path
                    d="M366.503 53.7378H382.159L392.05 83.2222C392.894 85.7847 393.472 88.8159 393.784 92.3159H394.066C394.409 89.0972 395.081 86.0659 396.081 83.2222L405.784 53.7378H421.112L398.941 112.847C396.909 118.316 394.003 122.41 390.222 125.128C386.472 127.847 382.081 129.207 377.05 129.207C374.581 129.207 372.159 128.941 369.784 128.41V117.066C371.503 117.472 373.378 117.675 375.409 117.675C377.941 117.675 380.144 116.894 382.019 115.332C383.925 113.8 385.409 111.472 386.472 108.347L387.316 105.769L366.503 53.7378Z"
                    stroke="white"
                    strokeWidth="5"
                  />
                </g>
              </motion.g>
            </svg>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
