import React from "react";

interface IArrowProps {
  isUpperDirection?: boolean;
}

export const Arrow: React.FC<IArrowProps> = ({ isUpperDirection = false }) => {
  const width = "1.2rem";

  if (isUpperDirection)
    return (
      <svg style={{ width: width }} viewBox="0 0 94 81">
        <g id="arrow-up">
          <g clipPath="url(#clip0)">
            <circle
              id="Ellipse 9"
              cx="86.2023"
              cy="73.194"
              r="7.47395"
              transform="rotate(-180 86.2023 73.194)"
              fill="#5E72E4"
            ></circle>
            <circle
              id="Ellipse 9_2"
              cx="7.83251"
              cy="73.194"
              r="7.47395"
              transform="rotate(-180 7.83251 73.194)"
              fill="#5E72E4"
            ></circle>
            <line
              id="Line 12"
              x1="86.4914"
              y1="73.5079"
              x2="41.6879"
              y2="28.7043"
              stroke="#5E72E4"
              strokeWidth="15"
            ></line>
            <line
              id="Line 13"
              x1="52.2945"
              y1="28.7047"
              x2="7.49092"
              y2="73.5082"
              stroke="#5E72E4"
              strokeWidth="15"
            ></line>
          </g>
        </g>
        <defs>
          <rect
            x="93.6763"
            y="80.668"
            width="93.3177"
            height="80"
            transform="rotate(-180 93.6763 80.668)"
            fill="white"
          ></rect>
        </defs>
      </svg>
    );

  return (
    <svg style={{ width: width }} viewBox="0 0 94 90">
      <g id="arrow-down">
        <circle cx="7.47395" cy="7.47395" r="7.47395" fill="#5E72E4"></circle>
        <circle cx="85.8438" cy="7.47395" r="7.47395" fill="#5E72E4"></circle>
        <line
          x1="7.18487"
          y1="7.16008"
          x2="51.9884"
          y2="51.9636"
          stroke="#5E72E4"
          strokeWidth="15"
        ></line>
        <line
          x1="41.3818"
          y1="51.9633"
          x2="86.1853"
          y2="7.15976"
          stroke="#5E72E4"
          strokeWidth="15"
        ></line>
      </g>
    </svg>
  );
};
