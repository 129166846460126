import { ECategory, ESort } from "./enums";
import { IBazosState } from "./IBazosState";
import { ILocale } from "./ILocale";

export interface ISettings {
  keyword: string;
  amountFrom: number | undefined;
  amountTo: number | undefined;
  location: ILocale;
  aukro: boolean;
  bazos: boolean;
  marketplace: boolean;
  sbazar: boolean;
  page: number;
  sort: ESort;
  category?: ECategory;
  marketplaceCursor?: string;
  bazosCategoryState?: IBazosState;
}

export const emptySettings: ISettings = {
  keyword: "",
  amountFrom: undefined,
  amountTo: undefined,
  location: {
    name: "",
    postCode: "",
    latitude: 0,
    longitude: 0,
    radius: 10,
  },
  aukro: true,
  bazos: true,
  marketplace: true,
  sbazar: true,
  page: 0,
  sort: ESort.Latest,
};

export const resetKeywordValue = "(.)|(.)";

export const compareSettings = (
  setting1: ISettings,
  setting2: ISettings
): boolean => {
  return (
    setting1.keyword === setting2.keyword &&
    setting1.amountFrom === setting2.amountFrom &&
    setting1.amountTo === setting2.amountTo &&
    setting1.sort === setting2.sort &&
    setting1.location.latitude === setting2.location.latitude &&
    setting1.location.longitude === setting2.location.longitude &&
    setting1.location.radius === setting2.location.radius &&
    setting1.aukro === setting2.aukro &&
    setting1.bazos === setting2.bazos &&
    setting1.marketplace === setting2.marketplace &&
    setting1.sbazar === setting2.sbazar &&
    setting1.category === setting2.category
  );
};

export const settingsSessionStorageKey = "user_settings";
export const settingsHistoryLocalStorageKey = "user_settings_history";

export const createParamUrl = (settings: ISettings): string => {
  let url = "?keyword=" + settings.keyword + "&sort=" + settings.sort;

  if (Boolean(settings.amountFrom)) url += "&from=" + settings.amountFrom;
  if (Boolean(settings.amountTo)) url += "&to=" + settings.amountTo;
  if (Boolean(settings.location.name))
    url += "&location=" + JSON.stringify(settings.location);

  return url;
};

export const getSettingsFromUrl = (): ISettings | null => {
  try {
    const searchParams = window.location.search;

    if (!Boolean(searchParams)) return null;
    const urlParams = new URLSearchParams(searchParams);

    const keyword = urlParams.get("keyword");
    const sort = urlParams.get("sort");
    const amountFrom = urlParams.get("from");
    const amountTo = urlParams.get("to");
    const location = urlParams.get("location");

    const settings = { ...emptySettings };

    if (keyword) settings.keyword = keyword;
    if (sort) settings.sort = parseInt(sort);
    if (amountFrom) settings.amountFrom = parseInt(amountFrom);
    if (amountTo) settings.amountTo = parseInt(amountTo);
    if (location) settings.location = JSON.parse(location);

    return settings;
  } catch {
    return null;
  }
};

export const initSettngs = () => {
  let settings: ISettings = { ...emptySettings };
  const pathSettings = getSettingsFromUrl();

  if (pathSettings) {
    settings = pathSettings;
  } else {
    const storageItem = sessionStorage.getItem(settingsSessionStorageKey);

    if (storageItem) {
      const sesstionSettings: ISettings = JSON.parse(storageItem);
      sesstionSettings.page = 0;
      sesstionSettings.keyword = "";

      settings = sesstionSettings;
    }
  }

  return { settings, searchOnLoad: Boolean(pathSettings) };
};
