import React from "react";
import { Item } from "./Item";
import { ISearchItem } from "../models/ISearchItem";

export const SearchResults: React.FC<{
  results: ISearchItem[];
}> = (props) => {
  return (
    <>
      {props.results.map((result, index) => {
        return <Item key={index} {...result} />;
      })}
    </>
  );
};
