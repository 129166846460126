export enum ECategory {
  AutoMoto = 1,
  Zvirata = 2,
  DetskyBazar = 3,
  Reality = 4,
  Elektronika = 5,
  DumZahradaStroje = 6,
  Nabytek = 7,
  Sport = 8,
  HudbaKnihyZabava = 9,
  ObleceniObuvDoplnky = 10,
  Sluzby = 11,
  ZdraviKrasa = 12,
  OstatniStarozitnostiUmeni = 13,
}

export const CategoryDefaultLabel = "Výchozí";

export enum ECategoryLabel {
  AutoMoto = "Auto-moto",
  Zvirata = "Zvířata",
  DetskyBazar = "Dětský bazar",
  Reality = "Reality",
  Elektronika = "Elektronika",
  DumZahradaStroje = "Dům/zahrada/stroje",
  Nabytek = "Nábytek",
  Sport = "Sport",
  HudbaKnihyZabava = "Hudba/Knihy/Zábava",
  ObleceniObuvDoplnky = "Oblečení/Obuv/Doplňky",
  Sluzby = "Služby",
  ZdraviKrasa = "Zdraví a krása",
  OstatniStarozitnostiUmeni = "Ostatní/Starožitnosti/Umění",
}

export const CategoryMap: Record<ECategory, ECategoryLabel> = {
  [ECategory.AutoMoto]: ECategoryLabel.AutoMoto,
  [ECategory.Zvirata]: ECategoryLabel.Zvirata,
  [ECategory.DetskyBazar]: ECategoryLabel.DetskyBazar,
  [ECategory.Reality]: ECategoryLabel.Reality,
  [ECategory.Elektronika]: ECategoryLabel.Elektronika,
  [ECategory.DumZahradaStroje]: ECategoryLabel.DumZahradaStroje,
  [ECategory.Nabytek]: ECategoryLabel.Nabytek,
  [ECategory.Sport]: ECategoryLabel.Sport,
  [ECategory.HudbaKnihyZabava]: ECategoryLabel.HudbaKnihyZabava,
  [ECategory.ObleceniObuvDoplnky]: ECategoryLabel.ObleceniObuvDoplnky,
  [ECategory.Sluzby]: ECategoryLabel.Sluzby,
  [ECategory.ZdraviKrasa]: ECategoryLabel.ZdraviKrasa,
  [ECategory.OstatniStarozitnostiUmeni]:
    ECategoryLabel.OstatniStarozitnostiUmeni,
};
