import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Collapse } from "reactstrap";
import { api, scrollHandler, scrollToElement } from "../utils/";
import * as model from "../models/";
import * as component from "../components";

interface IAppProps extends model.ISettings {
  searchOnLoad: boolean;
}

export const App: React.FC<IAppProps> = (props) => {
  const [isOnLoadSearchDone, setIsOnLoadSearchDone] = useState<boolean>(
    !props.searchOnLoad
  );
  const [settings, setSettings] = useState<model.ISettings>(props);
  const [
    activeSearchSettings,
    setActiveSearchSettings,
  ] = useState<model.ISettings>(props);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [results, setResults] = useState<model.IResults[] | undefined>(
    undefined
  );
  const [isNoResult, setIsNoResult] = useState<boolean>(false);
  const [isAllResultsShowed, setIsAllResultsShowed] = useState<boolean>(false);
  const [isSearchFailed, setIsSearchFailed] = useState<boolean>(false);
  const [isAdvancedShow, setIsAdvancedShow] = useState<boolean>(false);
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false);

  const headerDiv = useRef<HTMLDivElement>(null);
  const contentDiv = useRef<HTMLDivElement>(null);

  const canSearch = Boolean(settings.keyword) && !isSearching;

  useEffect(() => {
    sessionStorage.setItem(
      model.settingsSessionStorageKey,
      JSON.stringify(activeSearchSettings)
    );
  }, [activeSearchSettings]);

  useEffect(() => {
    search(true, settings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings.sort, settings.category]);

  window.onscroll = () =>
    scrollHandler(
      contentDiv,
      showBackToTop,
      setShowBackToTop,
      isAllResultsShowed,
      canSearch,
      search
    );

  const search = async (
    isNewSearch: boolean,
    requestSettings: model.ISettings = activeSearchSettings
  ) => {
    if (!Boolean(requestSettings.keyword)) return;

    let targetSettings = { ...requestSettings };

    if (isNewSearch) {
      setResults([]);
      setIsAllResultsShowed(false);
      setIsNoResult(false);

      targetSettings.marketplaceCursor = undefined;
      targetSettings.bazosCategoryState = undefined;

      window.history.pushState(null, "", model.createParamUrl(targetSettings));
    }

    setIsSearching(true);

    try {
      const pageNumber = isNewSearch ? 1 : activeSearchSettings.page + 1;

      const pageRequest = {
        ...targetSettings,
        page: pageNumber,
      };

      const response: model.ISearchResponse = await api(
        "POST",
        "search/GetResults",
        pageRequest
      );

      const hasNoItems = response.searchItems.length === 0;

      if (isNewSearch && hasNoItems) {
        setIsNoResult(true);
      }

      if (hasNoItems) {
        setIsAllResultsShowed(true);
      }

      const actualResults: model.IResults[] = isNewSearch ? [] : results ?? [];

      actualResults.push({ page: pageNumber, results: response.searchItems });

      setResults(actualResults);

      if (isNewSearch) scrollToElement(contentDiv);

      setActiveSearchSettings({
        ...pageRequest,
        marketplaceCursor: response.temp.marketplaceCursor,
        bazosCategoryState: response.temp.bazosCategoryState,
      });
    } catch (e) {
      console.log(e);
      setIsSearchFailed(true);
    } finally {
      setIsSearching(false);
    }
  };

  if (!isOnLoadSearchDone) {
    setIsOnLoadSearchDone(true);
    search(true);
  }

  return (
    <>
      <component.Alert
        setIsAlertVisible={setIsSearchFailed}
        visible={isSearchFailed}
        color="danger"
      >
        Vyhledávání se nezdařilo, opakujte akci později.
      </component.Alert>
      <div ref={headerDiv} className="header pt-4 pb-4">
        <div className="header-body">
          <Container fluid>
            <Row>
              <Col xl={4} lg={4}>
                <component.Header />
              </Col>
              <Col xl={7} lg={8}>
                <component.SearchBar
                  isSearching={isSearching}
                  setSettings={setSettings}
                  settings={settings}
                  search={search}
                />
                <component.Settings
                  settings={settings}
                  setSettings={setSettings}
                  isAdvancedSettingsShow={isAdvancedShow}
                  search={search}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Container
        fluid={true}
        onClick={() => setIsAdvancedShow(!isAdvancedShow)}
        className="all-settings"
      >
        <Row className="text-center">
          <Col className="p-0">
            <p className="more-settings">Nastavení portálů</p>
            <component.Arrow isUpperDirection={isAdvancedShow} />
            <hr className="m-0" />
          </Col>
        </Row>
      </Container>
      <div ref={contentDiv} className="container-fluid content pt-4">
        <Collapse isOpen={results === undefined}>
          <component.Welcome />
        </Collapse>
        {results === undefined ? null : (
          <component.UserSearch
            isLoading={isSearching}
            results={results === undefined ? [] : results}
            isNoResult={isNoResult}
            isShowedAll={isAllResultsShowed}
          ></component.UserSearch>
        )}
      </div>
      <component.Feedback show={Boolean(results === undefined)} />
      <component.BackToTop div={headerDiv} show={showBackToTop} />
    </>
  );
};
