import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { scrollToElement } from "../utils";

interface IBackToTopProps {
  show: boolean;
  div: React.RefObject<HTMLDivElement>;
}

export const BackToTop: React.FC<IBackToTopProps> = ({ show, div }) => (
  <AnimatePresence>
    {show ? (
      <motion.button
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        color="primary"
        className="btn back-button"
        onClick={() => scrollToElement(div)}
        style={{ zIndex: 99999 }}
      >
        <i className="ni ni-bold-up" />
      </motion.button>
    ) : null}
  </AnimatePresence>
);
