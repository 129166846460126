export enum ESort {
  Latest = 1,
  MostExpensive = 2,
  Cheapest = 3,
}

export enum ESortLabel {
  Latest = "Nejnovejší",
  MostExpensive = "Nejdražší",
  Cheapest = "Nejlevnější",
}

export const ESortMapper: Record<ESort, ESortLabel> = {
  [ESort.Latest]: ESortLabel.Latest,
  [ESort.MostExpensive]: ESortLabel.MostExpensive,
  [ESort.Cheapest]: ESortLabel.Cheapest,
};
