import React from "react";
import {
  Col,
  CardBody,
  Card,
  CardTitle,
  CardFooter,
  Row,
  Badge,
} from "reactstrap";
import { getDateFormat, getPriceText } from "../utils/";
import { PortalIcon } from ".";
import { motion } from "framer-motion";
import { EPortalIconMapper, ISearchItem } from "../models";
// @ts-ignore
import ReactImageAppear from "react-image-appear";

export const Item: React.FC<ISearchItem> = ({
  portal,
  name,
  price,
  image,
  location,
  createdDate,
  link,
}) => {
  const date = new Date(createdDate);
  const noPhotoImage = require(`../assets/images/no-photo.png`);

  return (
    <motion.div
      className="col-xl-3 col-lg-4 col-sm-6 mb-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <a
        href={link}
        rel="noreferrer noopener"
        target="_blank"
        style={{ color: "#525f7f" }}
      >
        <Card className="card-animate">
          <Row
            style={{
              top: 0,
              right: 0,
              position: "absolute",
              padding: "0.4rem",
              zIndex: 99,
            }}
          >
            <Col>
              <PortalIcon iconName={EPortalIconMapper[portal]} />
            </Col>
          </Row>
          <Row
            style={{
              top: 0,
              left: 0,
              position: "absolute",
              padding: "0.6rem",
              zIndex: 99,
            }}
          >
            <Col>
              <Badge
                style={{
                  backgroundColor: "#fff",
                  color: "rgb(82, 95, 127)",
                }}
              >
                <i className="ni ni-calendar-grid-58 search-item-icon" />{" "}
                <span style={{ fontSize: "0.9rem" }}>
                  {getDateFormat(date)}
                </span>
              </Badge>
            </Col>
          </Row>
          <ReactImageAppear
            className="item-photo"
            alt={name}
            src={image || noPhotoImage}
            animationDuration="1s"
          />
          <CardBody style={{ padding: "0.8rem" }}>
            <CardTitle className="mb-0 text-truncate">
              <strong>{name}</strong>
            </CardTitle>
          </CardBody>
          <CardFooter>
            <Row>
              <Col lg={6} md={6} xs={6} className="text-left">
                <Row>
                  <Col className="text-truncate">
                    <i className="ni ni-money-coins search-item-icon" />{" "}
                    <strong>{getPriceText(price)}</strong>
                  </Col>
                </Row>
              </Col>
              <Col className="text-right text-truncate">
                <i className="ni ni-pin-3 search-item-icon" /> {location}
              </Col>
            </Row>
          </CardFooter>
        </Card>
      </a>
    </motion.div>
  );
};
