import React from "react";
import { Col, CardBody, Card, Row } from "reactstrap";
import useWindowDimensions from "../utils/hooks";
import { PortalIcon } from "./PortalIcon";

export interface IPortalSettingsProps {
  title: string;
  iconName: string;
  margin?: boolean;
  value: boolean;
  onChange: () => void;
}

export const PortalSetting: React.FC<IPortalSettingsProps> = ({
  title,
  iconName,
  margin = true,
  value,
  onChange,
}) => {
  const checkboxId = "checkbox_" + title;
  const { width } = useWindowDimensions();
  const cardClass = "card-stats" + (margin && width < 1200 ? " mb-4" : "");
  const xl = width > 1750 ? 2 : 3;
  const lg = width > 1050 ? 3 : 4;

  return (
    <Col xl={xl} lg={lg} md={3} sm={4} xs={6} disabled={value}>
      <Card className={cardClass} onClick={onChange}>
        <CardBody>
          <Row>
            <Col
              xl={7}
              lg={7}
              md={7}
              sm={7}
              xs={7}
              className="align-self-center"
            >
              <div className="custom-control custom-checkbox">
                <input
                  id={title}
                  checked={value}
                  onChange={(e) => e.preventDefault()}
                  className="custom-control-input"
                  type="checkbox"
                />
                <label
                  className="custom-control-label text-uppercase"
                  htmlFor={checkboxId}
                >
                  <span className="text-truncate">{title}</span>
                </label>
              </div>
            </Col>
            <Col xl={2} lg={2} md={2} sm={2} xs={2}>
              <PortalIcon
                iconName={iconName}
                imageHeight={25}
                totaHeight={40}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};
